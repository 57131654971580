import { trackEvent } from '@centered/analytics'
import { GetStaticProps } from 'next'
import { ComponentProps, useEffect } from 'react'
import LandingPage from '../components/v2-landing-page/LandingPage'
import { fetchLandingPageProps } from '../lib/fetchLandingPageProps'

type LandingPageProps = ComponentProps<typeof LandingPage>

export default function Page({
  featuredGroups,
}: LandingPageProps): JSX.Element {
  useEffect(() => {
    trackEvent('HomePageLoaded', {
      variant: 'home_test_2',
    })
  }, [])

  return <LandingPage featuredGroups={featuredGroups} />
}

export const getStaticProps: GetStaticProps<LandingPageProps> = async () => {
  const { coaches } = await fetchLandingPageProps(true)

  return {
    props: {
      featuredGroups: coaches,
    },
  }
}
